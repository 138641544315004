import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { List, Logo, Wrap, Block, Image, Text, LogoImage } from './styled';

const itemsTop = ['big'];
const items = ['img1', 'img2', 'img3', 'img4', 'img5'];

const brands = graphql`
  {
    big: file(relativePath: { eq: "landing/1/brands/big@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    img1: file(relativePath: { eq: "landing/1/brands/1@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    img2: file(relativePath: { eq: "landing/1/brands/2@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    img3: file(relativePath: { eq: "landing/1/brands/3@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    img4: file(relativePath: { eq: "landing/1/brands/4@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    img5: file(relativePath: { eq: "landing/1/brands/5@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

function Brands() {
  return (
    <Wrap>
      <StaticQuery
        query={brands}
        render={(data) => (
          <>
            {itemsTop.map((value) => (
              <Logo>
                <LogoImage key={value}>
                  <GatsbyImage
                    image={data[value].childImageSharp.gatsbyImageData}
                  />
                </LogoImage>
              </Logo>
            ))}
            <Text>
              <FormattedMessage id="landing1.brands.title" />
            </Text>
            <List>
              {items.map((value) => (
                <Block>
                  <Image key={value}>
                    <GatsbyImage
                      image={data[value].childImageSharp.gatsbyImageData}
                    />
                  </Image>
                </Block>
              ))}
            </List>
          </>
        )}
      />
    </Wrap>
  );
}

export default Brands;
