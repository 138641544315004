import styled from 'styled-components';
import { colors, media, mediaMax } from '../../../ui/common';
import TextUnderline from '../../../ui/text-underline';

export const Section = styled.section`
  padding-top: 64px;
  ${media.tablet`
    padding: 60px 0 0;
  `}
`;

export const Desc = styled.div`
  color: ${colors.color05};
  text-align: center;
  margin: 18px 0 24px;
  line-height: 1.75;
  font-weight: 300;
  ${media.tablet`
    margin: 22px 0 48px;
  `}
  ${media.medium`
    margin: 20px 0;
  `}
`;

export const Card = styled.div`
  margin-top: 60px;
  ${mediaMax.medium`
    margin-top: 50px;
  `};
  ${mediaMax.phone`
    margin-top: 40px;
  `};
  position: relative;
  overflow: hidden;
  padding-bottom: 56.2%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
  }
`;

export const Link = styled(TextUnderline)`
  font-size: 14px;
  margin-top: 0px !important;
`;

export const Bottom = styled.div`
  margin: 40px 0 116px;
  text-align: center;
  ${mediaMax.medium`
    margin: 30px 0 116px;
  `}
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  ${mediaMax.medium`
    max-width: 930px;
  `}
`;
