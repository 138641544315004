import styled from 'styled-components';
import { colors } from '../../../../ui/common';

/* eslint-disable-next-line import/prefer-default-export */
export const Wrap = styled.div`
  padding: 29px;
  text-align: center;
  background-color: ${colors.color01};
  color: #fff;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
`;
