import styled from 'styled-components';
import { colors, mediaMax } from '../../../../ui/common';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  margin: 50px auto 0px;
  max-width: 1200px;
  overflow: auto;
  ${mediaMax.phone`
    width: 100%;
    flex-wrap: wrap;
  `}
`;

export const Block = styled.div`
  width: 539px;
  height: 313px;
  margin: 15px;
  padding: 49px 30px 30px;
  background-color: #f7fbfb;
  border-radius: 4px;
  text-align: center;
  color: ${colors.color05};
  ${mediaMax.phone`
    width: 100%;
  `}
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  margin: 19px auto 19px;
  width: 475px;
  font-weight: normal;
  font-size: 24px;
  line-height: 162.4%;
  ${mediaMax.phone`
    width: 100%;
    font-size: 20px;
  `}
`;

export const Name = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 162.4%;
`;

export const StarWrap = styled.div`
  padding: 2px;
`;
