import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Wrap } from './styled';

function Footer() {
  return (
    <Wrap>
      <FormattedMessage id="landing1.footer.text" />
    </Wrap>
  );
}

export default Footer;
