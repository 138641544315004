import { useEffect } from 'react';
import { setCountry } from '../utils/api/countries';
import useLocale from './use-locale';

const useCountryLocale = (country, locale) => {
  const localeManager = useLocale();

  useEffect(() => {
    if (country) setCountry(country);
    if (locale) localeManager(locale);
  }, []);
};

export default useCountryLocale;
