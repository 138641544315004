import styled from 'styled-components';
import { colors, mediaMax } from '../../../../ui/common';

export const Wrap = styled.div`
  margin-top: 100px;
  padding: 106px 0 106px;
  color: ${colors.color01};
  font-weight: 300;
  font-size: 30px;
  line-height: 162.4%;
  text-align: center;
  ${mediaMax.phone`
    font-size: 20px;
  `}
`;

export const Logo = styled.div``;

export const LogoImage = styled.div`
  width: 155px;
  margin: 0 auto;
`;

export const Text = styled.div`
  max-width: 780px;
  margin: 47px auto 30px;
`;

export const List = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${mediaMax.tablet`
    flex-wrap: wrap;
  `}
`;

export const Block = styled.div``;

export const Image = styled.div`
  width: 105px;
  padding: 15px;
  margin: 0 auto;
`;
