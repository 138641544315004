import React, { useRef, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import getFluid from '../utils/get-fluid';
import Info from '../components/Products/Info';
import Difference from '../components/Products/Difference';
import FAQ from '../components/Products/FAQ';
import NavBar from '../components/Navbar';
import PopUpAdd from '../components/Products/PopUpAdd';
import usePathName from '../hooks/use-path-name';
import InfoMain from '../components/Products/Info/Main';
import Technology from '../components/Products/Technology';
import Promo from '../components/Products/Promo';
import Footer from '../components/Landing/1/Footer';
import Brands from '../components/Landing/1/Brands';
import Reviews from '../components/Landing/1/Reviews';
import Header from '../components/Landing/1/Header';
import useCountryLocale from '../hooks/use-country-locale';
import pagesInfo from '../domain/pages-info';

const specList = [
  {
    landing1_evachill: 'landing1.difference.evachill.desc',
    landing1_cost: 'landing1.difference.evachill.cost',
    landing1_cooling_capacity: 'landing1.difference.evachill.cooling_capacity',
    landing1_portability: 'landing1.difference.evachill.portability',
    landing1_power_consumption:
      'landing1.difference.evachill.power_consumption',
    landing1_noise_level: 'landing1.difference.evachill.noise_level',
  },
  {
    landing1_con: 'landing1.difference.con.desc',
    landing1_cost: 'landing1.difference.con.cost',
    landing1_cooling_capacity: 'landing1.difference.con.cooling_capacity',
    landing1_portability: 'landing1.difference.con.portability',
    landing1_power_consumption: 'landing1.difference.con.power_consumption',
    landing1_noise_level: 'landing1.difference.con.noise_level',
  },
  {
    landing1_fan: 'landing1.difference.fan.desc',
    landing1_cost: 'landing1.difference.fan.cost',
    landing1_cooling_capacity: 'landing1.difference.fan.cooling_capacity',
    landing1_portability: 'landing1.difference.fan.portability',
    landing1_power_consumption: 'landing1.difference.fan.power_consumption',
    landing1_noise_level: 'landing1.difference.fan.noise_level',
  },
];

const promo = graphql`
  {
    right: file(relativePath: { eq: "landing/1/promo/1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    left: file(relativePath: { eq: "landing/1/promo/2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    map1: file(relativePath: { eq: "product-page/evachill/map1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    map2: file(relativePath: { eq: "product-page/evachill/map2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image1: file(
      relativePath: { eq: "product-page/evachill/gallery/image-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image2: file(
      relativePath: { eq: "product-page/evachill/gallery/image-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image3: file(
      relativePath: { eq: "product-page/evachill/gallery/image-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image4: file(
      relativePath: { eq: "product-page/evachill/gallery/image-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image5: file(
      relativePath: { eq: "product-page/evachill/gallery/image-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle1: file(
      relativePath: { eq: "landing/1/gallery/lifestyle/lifestyle1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle2: file(
      relativePath: { eq: "landing/1/gallery/lifestyle/lifestyle2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle3: file(
      relativePath: { eq: "landing/1/gallery/lifestyle/lifestyle3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle4: file(
      relativePath: { eq: "landing/1/gallery/lifestyle/lifestyle4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    vpreview: file(
      relativePath: { eq: "product-page/evachill/gallery/video.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evachill: file(relativePath: { eq: "product-page/evachill.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evasmart: file(relativePath: { eq: "product-page/evasmart.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evalight: file(relativePath: { eq: "product-page/evalight.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    aircon: file(relativePath: { eq: "landing/1/product/aircon.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    fan: file(relativePath: { eq: "landing/1/product/fan.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    technology: file(relativePath: { eq: "product-page/technology.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white01: file(
      relativePath: { eq: "product-page/evachill/gallery/white/01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white02: file(
      relativePath: { eq: "product-page/evachill/gallery/white/02.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white03: file(
      relativePath: { eq: "product-page/evachill/gallery/white/03.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white04: file(
      relativePath: { eq: "product-page/evachill/gallery/white/04.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white05: file(
      relativePath: { eq: "product-page/evachill/gallery/white/05.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    grey01: file(
      relativePath: { eq: "product-page/evachill/gallery/grey/01.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    grey02: file(
      relativePath: { eq: "product-page/evachill/gallery/grey/02.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    grey03: file(
      relativePath: { eq: "product-page/evachill/gallery/grey/03.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    grey04: file(
      relativePath: { eq: "product-page/evachill/gallery/grey/04.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    pink01: file(
      relativePath: { eq: "product-page/evachill/gallery/pink/01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    pink02: file(
      relativePath: { eq: "product-page/evachill/gallery/pink/02.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    pink03: file(
      relativePath: { eq: "product-page/evachill/gallery/pink/03.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    pink04: file(
      relativePath: { eq: "product-page/evachill/gallery/pink/04.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

const info = pagesInfo.evachill;

export default function PromoEvachillGfd65yfglhk() {
  const infoRef = useRef(null);
  const [selected, setData] = useState({});

  useCountryLocale('AU', 'en-AU');

  const pathName = usePathName();

  return (
    <StaticQuery
      query={promo}
      render={(data) => {
        const fluids = new Map([
          [
            'common',
            ['lifestyle1', 'lifestyle2', 'lifestyle3', 'lifestyle4'].map(
              (key) => getFluid(data, key)
            ),
          ],
          [
            'white',
            ['white01', 'white02', 'white03', 'white04', 'white05'].map((key) =>
              getFluid(data, key)
            ),
          ],
          [
            'grey',
            ['grey01', 'grey02', 'grey03', 'grey04'].map((key) =>
              getFluid(data, key)
            ),
          ],
          [
            'pink',
            ['pink01', 'pink02', 'pink03', 'pink04'].map((key) =>
              getFluid(data, key)
            ),
          ],
        ]);

        const youtube = {
          url: 'https://www.youtube.com/embed/JHEd-50uPyo',
          aspectRatio: 16 / 9,
          fluid: getFluid(data, 'image1'),
          title: 'evaCHILL. Be Cool. Anywhere. Anytime. YouTube video.',
        };

        return (
          <Info
            data={info}
            productName={info.title}
            fluids={fluids}
            youtube={youtube}
            onChange={setData}
          >
            <NavBar short logoTo={pathName} />
            <Header />
            <Reviews />
            <InfoMain ref={infoRef} discount={50} />
            <Promo
              isRight
              image={data.right.childImageSharp.gatsbyImageData}
              title={<FormattedMessage id="evachill.landing1.right.title" />}
            />
            <Difference
              info={info}
              images={[
                data.evachill.childImageSharp.gatsbyImageData,
                data.aircon.childImageSharp.gatsbyImageData,
                data.fan.childImageSharp.gatsbyImageData,
              ]}
              list={specList}
            />
            <Promo
              image={data.left.childImageSharp.gatsbyImageData}
              title={<FormattedMessage id="evachill.landing1.left.title" />}
              text={<FormattedMessage id="evachill.landing1.left.text" />}
            />
            <Technology linkVisible={false} />
            <Brands />
            <FAQ />
            <PopUpAdd data={selected} targetRef={infoRef} />
            <Footer />
          </Info>
        );
      }}
    />
  );
}
