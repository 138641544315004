import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Headline } from '../ui/common';
import { Container, Section, Desc, Link, Card, Bottom } from './styled';

function Technology({ link, linkVisible }) {
  const intl = useIntl();

  return (
    <Section>
      <Headline>
        <FormattedMessage id="technology.headline" />
      </Headline>
      <Desc>
        <FormattedMessage id="technology.desc" />
      </Desc>
      <Container>
        <Card>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/Ftrnsw5kU9c"
            title={intl.formatMessage({ id: 'technology.video.title' })}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Card>
      </Container>
      {linkVisible && (
        <Bottom>
          <Link to={link}>
            <FormattedMessage id="technology.link" />
          </Link>
        </Bottom>
      )}
    </Section>
  );
}

export default Technology;

Technology.propTypes = {
  link: PropTypes.string,
  linkVisible: PropTypes.bool,
};
Technology.defaultProps = {
  link: '',
  linkVisible: true,
};
