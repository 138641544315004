import styled from 'styled-components';
import LocalizedLink from '../components/localized-link';
import { colors } from './common';

const TextUnderline = styled(LocalizedLink)`
  text-decoration: none !important;
  position: relative;
  font-weight: 600;
  font-size: 18px;
  @media (max-width: 1280px) {
    margin-top: 60px;
  }
  @media (max-width: 960px) {
    margin-top: 280px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  color: ${colors.color06};
  display: inline-block;
  ::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: ${colors.color06};
    display: block;
    position: absolute;
    bottom: -6px;
  }
`;

export default TextUnderline;
