import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, StaticQuery } from 'gatsby';
import { Title, Text, Image, Section, Button, ButtonWrap } from './styled';
import Context from '../../../Products/Info/Context';

const img = graphql`
  {
    header: file(relativePath: { eq: "landing/1/header/header@2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

function Header() {
  const { addItemToCart } = useContext(Context);

  return (
    <StaticQuery
      query={img}
      render={(data) => (
        <Image fluid={data.header.childImageSharp.fluid} alt="">
          <Section>
            <Title>
              <FormattedMessage id="landing1.header.title" />
            </Title>
            <Text>
              <FormattedMessage id="landing1.header.text" />
            </Text>
            <ButtonWrap>
              <Button onClick={addItemToCart} extended>
                <FormattedMessage id="landing1.header.add_to_cart" />
              </Button>
            </ButtonWrap>
          </Section>
        </Image>
      )}
    />
  );
}

export default Header;
