import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { mediaMax } from '../../../../ui/common';
import { ButtonBig } from '../../../../ui/buttonDefault';

export const Section = styled.div`
  color: #fff;
  padding-top: 111px;
  padding-left: 165px;
  ${mediaMax.tablet`
    padding-top: 48px;
    padding-left: 99px;
  `}
  ${mediaMax.phone`
    padding-top: 60px;
    padding-left: 0px;
  `}
`;

export const Title = styled.h1`
  width: 425px;
  font-weight: 500;
  font-size: 52px;
  line-height: 122.9%;
  ${mediaMax.tablet`
    width: 372px;
    font-size: 48px;
  `}
  ${mediaMax.phone`
    text-align: center;
    width: 300px;
    margin: 60px auto 0px;
    font-size: 32px;
  `}
`;

export const Text = styled.div`
  margin-top: 20px;
  width: 388px;
  font-weight: 500;
  font-size: 18px;
  line-height: 170%;
  ${mediaMax.phone`
    text-align: center;
    width: 324px;
    margin: 20px auto 0px;
  `}
`;

export const Image = styled(BackgroundImage)`
  margin-top: 80px !important;
  height: 543px;
`;

export const ButtonWrap = styled.div`
  ${mediaMax.phone`
    text-align: center;
  `}
`;

export const Button = styled(ButtonBig)`
  width: 200px;
  margin-top: 40px;
  ${mediaMax.phone`
    width: 218px;
    margin: 30px auto 0px;
  `}
`;
