import React from 'react';
import { Wrap, Block, Top, Text, Name, StarWrap } from './styled';
import { colors } from '../../../../ui/common';

const data = [
  {
    user: '- Samuel',
    text: '“I am using cooler in the semi truck sleeper! Very good for small room”',
    stars: 5,
  },
  {
    user: '- William',
    text: '“This personal air conditioner exceeds every and any expectation I may have had. It is truly amazing, even at twice the price that I paid”',
    stars: 5,
  },
];

/* eslint-disable react/no-unknown-property */
function Star() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.92766 0.740946C7.83705 0.591369 7.67488 0.5 7.5 0.5C7.32512 0.5 7.16295 0.591369 7.07234 0.740946L5.03403 4.10588L1.20391 5.00461C1.03366 5.04456 0.896646 5.17056 0.842605 5.33688C0.788564 5.5032 0.825347 5.68567 0.939605 5.81806L3.50998 8.79644L3.18115 12.7168C3.16653 12.8911 3.24402 13.0603 3.3855 13.1631C3.52698 13.2659 3.71189 13.2873 3.87311 13.2196L7.5 11.6954L11.1269 13.2196C11.2881 13.2873 11.473 13.2659 11.6145 13.1631C11.756 13.0603 11.8335 12.8911 11.8189 12.7168L11.49 8.79644L14.0604 5.81806C14.1747 5.68567 14.2114 5.5032 14.1574 5.33688C14.1034 5.17056 13.9663 5.04456 13.7961 5.00461L9.96597 4.10588L7.92766 0.740946Z"
        fill={colors.color05}
        stroke={colors.color05}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
/* eslint-enable react/no-unknown-property */

function Reviews() {
  return (
    <Wrap>
      {data.map((v) => (
        <Block>
          <Top>
            {[...Array(v.stars)].map(() => (
              <StarWrap>
                <Star />
              </StarWrap>
            ))}
          </Top>
          <Text>{v.text}</Text>
          <Name>{v.user}</Name>
        </Block>
      ))}
    </Wrap>
  );
}

export default Reviews;
